/** @jsxImportSource @emotion/react */

import { useTranslation } from "react-i18next";
import {
  CallToActionButton,
  Column,
  FlowBox,
  Image,
  Row,
  SubTitle,
  Title,
  TranslatedList,
} from "../../components";
import { desktop } from "../../theme";

export const GenesisNFTs: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Column>
      <Title
        css={(theme) => ({ [desktop]: { textAlign: "start" } })}
        i18nKey="nfts.title"
      >
        <strong>Immortl </strong>NFTs
      </Title>
      <FlowBox css={{ paddingTop: 32 }}>
        <Column
          css={{
            justifyContent: "flex-start",
            [desktop]: { flexBasis: "35%", paddingRight: 32 },
          }}
        >
          <SubTitle i18nKey="nfts.subtitle">hodlers</SubTitle>
          <TranslatedList keyPrefix="nfts.highlights" n={3} checkmark={false} />
          <CallToActionButton href="https://opensea.io/collection/one-immortl-gods">
            {t("nfts.link")}
          </CallToActionButton>
        </Column>
        <Column
          css={{
            justifyContent: "flex-start",
            [desktop]: { flexBasis: "65%" },
          }}
        >
          <video
            autoPlay
            muted
            loop
            css={(theme) => ({
              zIndex: 10,
              marginTop: 32,
              width: "100%",
            })}
          >
            <source src="/assets/images/genesis_nfts.mp4" />
          </video>
        </Column>
      </FlowBox>
    </Column>
  );
};
