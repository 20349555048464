/** @jsxImportSource @emotion/react */

import { Trans, useTranslation } from "react-i18next";
import {
  Button,
  CallToActionButton,
  Column,
  FlowBox,
  Image,
  Row,
  StyledA,
  SubTitle,
  Text,
  Title,
  TranslatedList,
} from "../../components";
import { desktop } from "../../theme";

export const Swap: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Column>
      <Title css={{ [desktop]: { textAlign: "start" } }} i18nKey="swap.title">
        <strong>Busy </strong> Swap
      </Title>
      <SubTitle i18nKey="swap.subtitle">
        Find the best <strong>swap rate</strong> on all supported chains
      </SubTitle>
      <FlowBox
        css={{
          [desktop]: { marginTop: "3vh", justifyContent: "space-between" },
        }}
      >
        <Column
          css={{ [desktop]: { flexBasis: "60%", justifyContent: "center" } }}
        >
          <TranslatedList keyPrefix="swap.highlights" n={3} checkmark={false} />
          <CallToActionButton href="https://app.one-immortl.io/swap">{t("swap.swapNow")}</CallToActionButton>
        </Column>
        <Column
          css={{
            marginTop: 32,
            [desktop]: { flexBasis: "30%", order: -1, marginTop: 0 },
          }}
          hideOnMobile
        >
          <Image transition="slide-left" image="swap.png" width="30%" />
        </Column>
      </FlowBox>
    </Column>
  );
};
