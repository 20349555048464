import React, { useEffect, useMemo, useRef } from "react";


type Props = { 
    onEnter?: () => any, 
    onLeave?: () => any,
    children?: React.ReactNode 
}

export const ViewportListener: React.FC<Props> = ({ children, onEnter, onLeave }) => {
    const ref = useRef<HTMLDivElement>(null);
    const hasEnteredRef = useRef<boolean>(false);
    const hasLeftRef = useRef<boolean>(false);

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) => {
                if (hasLeftRef.current) return;
                if (entry.isIntersecting && !hasEnteredRef.current) {
                    onEnter?.()
                    hasEnteredRef.current = true;
                } else if (!entry.isIntersecting && hasEnteredRef.current) {
                    onLeave?.()
                    hasLeftRef.current = true;
                    hasEnteredRef.current = false;
                }
            }),
        [],
    );

    useEffect(() => {
        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [ref, observer]);
    return <div ref={ref}>{children}</div>
}