import { css, Global, Theme, useTheme } from "@emotion/react";

const breakpoints = [576, 1024];

export const [mobile, tablet] = breakpoints.map(
  (bp) => `@media (max-width: ${bp}px)`
);

export const desktop = `@media (min-width: ${breakpoints[1]}px)`;

export const hideOnMobile = css`
  @media (max-width: ${breakpoints[1]}px) {
    display: none;
  }
`;

export const hideOnDesktop = css`
  @media (min-width: ${breakpoints[1]}px) {
    display: none;
  }
`;

const GlobalCss = (theme: Theme) => css`
  body {
    max-width: 100vw;
    overflow-x: hidden;
    font-family: ${theme.fonts.primary};
    font-weight: 700;
    background: ${theme.colors.bgBlack};
    strong {
      color: ${theme.colors.accent};
    }
    button {
      font-family: ${theme.fonts.primary};
      font-weight: 700;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 700;
    }
    ul {
      margin: 0;
      padding: 0;
      margin-top: 3em;
      margin-bottom: 3em;
      li {
        color: ${theme.colors.white};
        font-size: ${theme.fontSizes.medium}px;
        list-style: none;
        padding-left: 40px;
        padding-top: 6px;
        position: relative;
        &::before {
          color: ${theme.colors.accent};
          content: "· ";
          line-height: ${theme.fontSizes.big * 1.2}px;
          position: absolute;
          left: 0;
          top: 0;
          font-size: ${theme.fontSizes.big * 2}px;
        }
      }
    }
  }
`;

export const GlobalStyles = () => {
  const theme = useTheme();

  return <Global styles={GlobalCss(theme)} />;
};
