import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { hideOnDesktop } from "../../theme";

const glow = (color: string) => keyframes`
from, to {
    box-shadow: 0 0px 15px ${color}80;
}
50% {
    box-shadow: 0 0px 30px ${color}FF;
}
`;

export const StyledA = styled.a<{
  hideOnDesktop?: boolean;
  color?: string;
  outlined?: boolean;
}>(
  ({
    theme,
    hideOnDesktop: hd = false,
    color = theme.colors.accent,
    outlined = false,
  }) => [
    outlined
      ? {
          fontFamily: theme.fonts.primary,
          color: theme.colors.bgBlack,
          borderRadius: 4,
          backgroundColor: color,
          textDecoration: "none",
          margin: 16,
          padding: "8px 16px",
        }
      : {
          fontFamily: theme.fonts.primary,
          color: color,
          textDecoration: "none",
          fontWeight: 900,
          padding: 16,
          textShadow: `0px 0px 10px ${color}`,
        },
    hd && hideOnDesktop,
  ]
);

export const CallToActionButton = styled.a(({ theme }) => ({
  fontSize: theme.fontSizes.big,
  color: theme.colors.bgBlack,
  backgroundColor: theme.colors.primary,
  boxShadow: `0 0 16px ${theme.colors.primary}`,
  borderRadius: 16,
  padding: "8px 16px",
  textDecoration: "none",
  textAlign: "center",
  animation: `${glow(theme.colors.primary)} 2s ease infinite`,
}));
