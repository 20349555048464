/** @jsxImportSource @emotion/react */

import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import {
  CallToActionButton,
  Column,
  FlowBox,
  Image,
  OnEnterTransition,
  Row,
  SubTitle,
  Text,
  Title,
  TranslatedList,
  useTransition,
} from "../../components";
import { desktop } from "../../theme";

const List = styled.ul(({ theme }) => ({}));

const ListItem = styled.li(({ theme }) => ({
  color: theme.colors.white,
  fontSize: theme.fontSizes.medium,
  [desktop]: {
    fontSize: theme.fontSizes.medium,
  },
}));

export const Payments: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Column>
      <Title
        css={{ [desktop]: { textAlign: "start" } }}
        i18nKey="payments.title"
      >
        <strong>Immortl </strong> Payments
      </Title>
      <Column>
        <FlowBox>
          <Column flexBasis="65%">
            <SubTitle i18nKey="payments.privateUsage.title">
              <strong>Private</strong> usage
            </SubTitle>
            <Text i18nKey="payments.privateUsage.subtitle"></Text>
            <TranslatedList
              keyPrefix="payments.privateUsage.highlights"
              n={3}
            />
          </Column>
          <Column css={{ [desktop]: { flexBasis: "35%", padding: 20 } }}>
            <Image
              transition="slide-right"
              css={{ position: "sticky", top: 100 }}
              image="immortl-card.png"
            />
          </Column>
        </FlowBox>
        <FlowBox css={{ paddingTop: 32 }}>
          <Column
            css={{
              justifyContent: "flex-start",
              [desktop]: { flexBasis: "65%" },
            }}
          >
            <SubTitle i18nKey="payments.enterpriseSolution.title">
              <strong>Enterprise</strong> Solution
            </SubTitle>
            <Text i18nKey="payments.enterpriseSolution.subtitle.1">
              Seamless crypto payment system for your business.
            </Text>
            <Text i18nKey="payments.enterpriseSolution.subtitle.2">
              Grow your userbase by accepting crypto payments.
            </Text>
            <TranslatedList
              keyPrefix="payments.enterpriseSolution.highlights"
              n={3}
            />
            <Text i18nKey="payments.enterpriseSolution.introLink">
              Grow your userbase by accepting crypto payments.
            </Text>
            <CallToActionButton
              css={{ marginTop: 32, marginBottom: 32 }}
              href="https://one-immortl.gitbook.io/documentation/immortl-products/enterprise-payment-system"
            >
              {t("payments.enterpriseSolution.learnMore")}
            </CallToActionButton>
          </Column>
          <Column
            css={{ [desktop]: { flexBasis: "35%", padding: 20, order: -1 } }}
          >
            <Image
              transition="slide-left"
              css={{ position: "sticky", top: 100 }}
              image="immortl-wallet.jpeg"
            />
          </Column>
        </FlowBox>
      </Column>
    </Column>
  );
};
