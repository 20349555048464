/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react"
import { useEffect, useRef, useState } from "react"
import {Property} from "csstype"


const activeCSS = css`
    transform: translateX(0%) !important;
    opacity: 1 !important;
`


export const Carousel: React.FC<{imageList: string[], size?: Property.Width<string | number>, pauseOnClick?: boolean}> = ({imageList, size = "100%", pauseOnClick}) => {
    const [currendIdx, setCurrentIndex] = useState(0)
    const [width, setWidth] = useState<string | number>(0)
    const [height, setHeight] = useState(0);
    const [imgWidth, setImgWidth] = useState(0);
    const [onPause, setOnPause] = useState(false);
    const imgRef = useRef<HTMLImageElement>(null);
    const divRef = useRef<HTMLDivElement>(null);

    const nextImage = () => setCurrentIndex( idx => (idx + 1) % imageList.length )
    
    
    useEffect(() => {
        setWidth(typeof size === "number" ? `${size}px` : size);
        const i = setInterval(() => {
            if(divRef.current) {
                setImgWidth(divRef.current.clientWidth)
            }
        }, 100)
        return () => clearInterval(i);
    }, [])

    useEffect(() => {
        const i = setInterval(() => {
            if(imgRef.current) {
                const newHeight = imgRef.current.height
                setHeight(prevHeight => prevHeight > newHeight ? prevHeight : newHeight)
            }
        }, 100)
        return () => clearInterval(i);
    }, [imgWidth])

    useEffect(() => {
        if(imgWidth > 0) {
            if(onPause) {return;}
            const i = setInterval(() => {
                nextImage()
            }, 5000)
            return () => clearInterval(i)
        }
    },[imageList, imgWidth, onPause])

    return (
            <div
                ref={divRef}
                css={css`
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                position: relative;
                width: ${width};
                height: ${height}px;
                overflow: hidden;
                cursor: ${pauseOnClick ? "pointer" : "normal"};
                > img {
                    position: absolute;
                    transition: transform 1s ease-in-out, opacity 1s ease-in-out;
                    transform: translateX(-100%);
                    opacity: 0;
                }
            `}
        >
            {imgWidth > 0 && imageList.map((i, idx) => (
                <img 
                    ref={imgRef}
                    onClick={() => {
                        if(pauseOnClick) {
                            if(onPause) {
                                nextImage()
                            }
                            setOnPause(p => !p)
                        }
                    }} 
                    src={i}
                    key={idx} alt={`carousel ${idx}`} css={[{width: imgWidth}, idx === currendIdx && activeCSS]} />
            ))}
        </div>);
}