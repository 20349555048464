import styled from "@emotion/styled"
import React from "react"
import { desktop } from "../theme"
import { Anchored } from "./navigation"

const StyledAnchored = styled(Anchored)(({theme}) => ({
    padding: "5vh 5% 3vh 5%",
    
    [desktop]: {
        padding: "5vh 0 3vh 0",
        maxWidth: 1024,
        margin: "auto"
    }
}))
export const Section: React.FC<{id: string, children: React.ReactNode}> = ({id, children}) => {
    return <StyledAnchored as="section" id={id}>{children}</StyledAnchored>
}