import { useEffect, useRef } from "react";
import { Location, useLocation } from "react-router-dom"

export const LocationListener: React.FC<{children?: React.ReactNode, onLocationChangeChange:(newLocation: Location, oldLocation: Location | null) => any}> = ({children, onLocationChangeChange}) => {
    const location = useLocation();
    const oldLocation = useRef<Location | null>(null)

    useEffect(() => {
        onLocationChangeChange(location, oldLocation.current);
        oldLocation.current = location;
    }, [location, onLocationChangeChange])

    return <>{children}</>
}