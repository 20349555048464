/** @jsxImportSource @emotion/react */

import { Theme } from "@emotion/react";
import React, { ReactNode } from "react";
import { Trans } from "react-i18next";
import { desktop } from "../theme";
import {
  OnEnterTransition,
  Transitions,
  useTransition,
} from "./css-transitions";

export const Title: React.FC<
  {
    as?: React.ElementType;
    children: ReactNode;
    primary?: boolean;
    transition?: Transitions;
    i18nKey?: string;
  } & React.HTMLAttributes<HTMLHeadingElement>
> = ({
  as: Tag = "h1",
  children,
  primary = true,
  transition = "slide-left",
  i18nKey,
  ...props
}) => {
  const primaryColor = (theme: Theme) =>
    primary ? theme.colors.white : theme.colors.primary;
  const secondaryColor = (theme: Theme) =>
    primary ? theme.colors.primary : theme.colors.white;
  const [titleCss, titleTransition] = useTransition(transition);

  return (
    <OnEnterTransition transition={titleTransition}>
      <Tag
        css={(theme: Theme) => [
          titleCss,
          {
            color: primaryColor(theme),
            fontSize: theme.fontSizes.big,
            letterSpacing: -2,
            //textShadow: `0px 0px 10px ${theme.colors.accent}`,
            "> strong": {
              color: secondaryColor(theme),
              fontWeight: 900,
            },
            textAlign: "left",
            [desktop]: {
              textAlign: "normal",
              fontSize: theme.fontSizes.giant,
            },
          },
        ]}
        {...props}
      >
        <Trans i18nKey={i18nKey}>{children}</Trans>
      </Tag>
    </OnEnterTransition>
  );
};

export const SubTitle: React.FC<
  {
    as?: React.ElementType;
    children: ReactNode;
    i18nKey?: string;
  } & React.HTMLAttributes<HTMLHeadingElement>
> = ({ as: Tag = "span", i18nKey, children, ...props }) => {
  return (
    <Tag
      css={(theme: Theme) => ({
        color: theme.colors.white,
        fontSize: theme.fontSizes.medium,
        //textShadow: `0px 0px 10px ${theme.colors.white}`,
        "> strong": {
          color: theme.colors.accent,
          fontWeight: 900,
          textShadow: `0px 0px 10px ${theme.colors.accent}`,
        },
        [desktop]: {
          fontSize: theme.fontSizes.big,
        },
      })}
      {...props}
    >
      <Trans i18nKey={i18nKey}>{children}</Trans>
    </Tag>
  );
};
