/** @jsxImportSource @emotion/react */
import { keyframes } from "@emotion/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CallToActionButton,
  Column,
  FlowBox,
  Image,
  Row,
  Section,
  SubTitle,
  Title,
} from "../components";
import { desktop, mobile } from "../theme";
import { Partners, Token, Swap, Payments, Wallet, Team } from "./sections";
import { GenesisNFTs } from "./sections/GenesisNFTs";

const glow = keyframes`
from, to {
    background-position:top left;
}
50% {
    background-position:top right;
}`;

export const HomePage = () => {
  const [ratio, setRatio] = useState({ value: 1, asc: true });
  useEffect(() => {
    const int = setInterval(() => {
      const step = 0.01;
      setRatio((r) => {
        if (r.value >= 1.5) return { value: r.value - step, asc: false };
        if (r.value <= 1) return { value: r.value + step, asc: true };
        return { ...r, value: r.asc ? r.value + step : r.value - step };
      });
    }, 20);
    return () => clearInterval(int);
  }, []);
  const { t } = useTranslation();
  return (
    <Column
      css={{
        background: `radial-gradient(${Math.round(
          300 * ratio.value
        )}px ${Math.round(
          700 * ratio.value
        )}px at bottom center,rgba(255,255,255,.3) 0,rgba(255,255,255,.2) 20%,transparent 80%) no-repeat fixed`,
        [desktop]: {
          background: `radial-gradient(${Math.round(
            768 * ratio.value
          )}px ${Math.round(
            400 * ratio.value
          )}px at bottom center,rgba(255,255,255,.3) 0,rgba(255,255,255,.2) 20%,transparent 80%) no-repeat fixed`,
        },
      }}
    >
      <Column
        css={(theme) => ({
          height: "85vh",
          alignItems: "start",
          flex: "1 0 auto",
          position: "relative",
          backgroundColor: theme.colors.bgBlack,
          overflow: "hidden",
          [desktop]: {
            height: "100vh",
          },
        })}
      >
        <video
          autoPlay
          loop
          muted
          css={(theme) => ({
            width: "100vw",
            paddingTop: 26,
            position: "absolute",
            transform: "scale(3.2)",
            zIndex: 1,
            top: 0,
            left: 0,
            height: "90vh",
            overflow: "hidden",
            marginTop: "-10%",
            [desktop]: {
              paddingTop: 0,
              transform: "scale(1)",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: 0,
              bottom: 0,
              left: 0,
              right: 0,
              height: "100vh",
            },
          })}
        >
          <source src="/assets/images/background-video.mp4" />
        </video>
        <FlowBox
          css={{
            zIndex: 10,
            justifyContent: "end",
            alignItems: "normal",
            flex: 1,
            padding: 32,
            [desktop]: {
              justifyContent: "end",
              maxWidth: 1024,
              margin: "auto",
              marginTop: 100,
            },
          }}
        >
          <Column css={{ justifyContent: "end" }}>
            <Title
              css={(theme) => ({
                marginBottom: 8,
                visibility: "visible",
                [mobile]: { marginTop: 26, visibility: "hidden" },
              })}
            >
              <strong>{t("title.line1")}</strong>
            </Title>
            <SubTitle
              css={{ textAlign: "center", [desktop]: { textAlign: "left" } }}
            >
              {t("title.subtitle")}
            </SubTitle>
          </Column>
          <Column
            css={(theme) => ({
              justifyContent: "end",
              [desktop]: {
                flexBasis: "40%",
                marginBottom: theme.fontSizes.big,
              },
            })}
          >
            <CallToActionButton
              css={{ marginTop: 16 }}
              href="https://one-immortl.gitbook.io/documentation/white-paper-v2"
            >
              {t("whitepaper")}
            </CallToActionButton>
          </Column>
        </FlowBox>
      </Column>
      <Section id="swap">
        <Swap />
      </Section>
      <Section id="payments">
        <Payments />
      </Section>
      <Section id="Wallet">
        <Wallet />
      </Section>
      <Section id="token">
        <Token />
      </Section>
      <Section id="NFTs">
        <GenesisNFTs />
      </Section>
      <Section id="team">
        <Team />
      </Section>
      <Section id="partners">
        <Partners />
      </Section>
    </Column>
  );
};
