
import { ThemeProvider } from '@emotion/react';
import './App.css';
import { Router } from './navigation';
import { GlobalStyles, theme } from './theme';
import "./i18n"

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Router />
    </ThemeProvider>
  );
}

export default App;
