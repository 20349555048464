/** @jsxImportSource @emotion/react */

import { useTranslation } from "react-i18next";
import {
  CallToActionButton,
  Column,
  FlowBox,
  SubTitle,
  Text,
  Title,
  TranslatedList,
} from "../../components";
import { desktop } from "../../theme";

export const Wallet: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Column flex={1}>
      <Title css={{ [desktop]: { textAlign: "start" } }} i18nKey="wallet.title">
        <strong>Immortl </strong> Wallet
      </Title>
      <Column>
        <FlowBox>
          <Column flexBasis="50%">
            <SubTitle i18nKey="wallet.subtitle">
              Non-custodial <strong>crypto wallet</strong>
            </SubTitle>
            <Text i18nKey="wallet.description"> </Text>
            <TranslatedList keyPrefix="wallet.highlights" n={6} />
            <CallToActionButton href="https://wallet.one-immortl.io">
              {t("tryNow")}
            </CallToActionButton>
          </Column>
          <Column css={{ [desktop]: { flexBasis: "50%", padding: 20 } }}>
            <video
              autoPlay
              loop
              muted
              css={(theme) => ({
                zIndex: 10,
                width: "100%",
              })}
            >
              <source src="/assets/images/wallet_video.mp4" />
            </video>
          </Column>
        </FlowBox>
      </Column>
    </Column>
  );
};
