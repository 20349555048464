/** @jsxImportSource @emotion/react */

import { HTMLAttributes } from "react";
import { Trans } from "react-i18next";

export const Text: React.FC<{i18nKey?: string} & HTMLAttributes<any>> = ({children, i18nKey, ...props}) => (
    <p {...props} css={
        (theme) => 
        ({
            color: theme.colors.secondary,
            marginBottom: 4,
            marginTop: 0,
            fontSize: theme.fontSizes.medium,
            "> strong": {
                fontWeight: "bold",
                color: theme.colors.accent
            }
        }
    )}>
        <Trans i18nKey={i18nKey}>
            {children}
        </Trans>
    </p>
) 