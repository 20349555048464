/** @jsxImportSource @emotion/react */

import { css, Interpolation, Theme } from "@emotion/react";
import styled from "@emotion/styled";

import { Fragment, ReactNode, useEffect, useState } from "react";


export const Background = styled.div<{height?: number}>(({ theme }) => ({
    pointerEvents: "none",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: -1,
    overflow: "hidden"
})) 

const opaqueCSS = css`
    opacity:1 !important;
    filter: alpha(opacity=1) !important;
    transform: scale(1.1);
`

export const ImageBackground: React.FC<{imageList: string[], children?: ReactNode, css?: Interpolation<Theme>}> = ({imageList, children, ...props}) => {
    const [currendIdx, setCurrentIndex] = useState(0)
    

    useEffect(() => {
        const i = setInterval(() => {
            setCurrentIndex( idx => (idx + 1) % imageList.length )
        }, 5000)
        return () => clearInterval(i)
    },[imageList])

    return <Fragment>
            <Background
                css={css`
                > img {
                    position: absolute;
                    left:0;
                    width: 100%;
                    transition: opacity 1s ease-in-out, transform 5s ease-in-out;
                    opacity: 0;
                    filter: alpha(opacity=0);
                }
            `}
        >
            {imageList.map((i, idx) => (
                <img src={i} key={idx} alt={`background ${idx}`} css={idx === currendIdx && opaqueCSS} />
            ))}
        </Background>
        {children}
    </Fragment>
}