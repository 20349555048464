import { css, SerializedStyles } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { ViewportListener } from "./ViewportListener";

type Direction = "left" | "right" | "bottom"

class CssTransition {
    private isActivated: boolean;
    private cssFunction: (activated: boolean) => SerializedStyles;
    private listeners: ((css: SerializedStyles) => any)[] = []


    constructor(fn: (activated: boolean) => SerializedStyles) {
        this.isActivated = false
        this.cssFunction = fn;
    }

    addListener(l: (css: SerializedStyles) => any) {
        this.listeners.push(l)
    }

    public activate(activated = true) {
        this.isActivated = activated;
        this.listeners.forEach(f => f(this.css))
    }

    get css() {
        return this.cssFunction(this.isActivated)
    }

    clone() {
        return new CssTransition(this.cssFunction);
    }

    get isEmpty() {
        return this.cssFunction(true).styles === this.cssFunction(false).styles
    }
}

const baseState = {
    "left":  "translateX(-100%)",
    "right": "translateX(100%)",
    "bottom": "translateY(100%)"
}
const activatedState = {
    "left":  "translateX(0%)",
    "right": "translateX(0%)",
    "bottom": "translateY(0%)"
}

export const slideTransition = (direction: Direction = "left") => {
    return new CssTransition(activated => css`
    transition: all 1s ease;
    opacity: ${activated ? 1 : 0};
    transform: ${activated ? activatedState[direction] : baseState[direction]};
`)
}

export const opacityTransition = () => {
    return new CssTransition(activated => css`
    transition: opacity 1s ease;
    opacity: ${activated ? "1" : "0"};
`)
}

const transitions = {
    "slide-left": () => slideTransition("left"),
    "slide-right": () => slideTransition("right"),
    "slide-bottom": () => slideTransition("bottom"),
    "opacity": () => opacityTransition(),
    "none": () => new CssTransition(() => css()) 
}

export type Transitions = keyof typeof transitions

export const useTransition = (transition: CssTransition | Transitions): [SerializedStyles, CssTransition] => {
    const localTransition  =  transition instanceof CssTransition ? transition.clone() : transitions[transition]();
    const [css, setCss] = useState(localTransition.css)

    useEffect(() => {
        localTransition.addListener(css => {
            setCss(css)
        })
    }, []);
    
    return [css, localTransition];
}

export const withDelay = (style: SerializedStyles, delay: string) => {
    return css([style, {transitionDelay: delay}]);
}

export const OnEnterTransition: React.FC<{transition: CssTransition, reenter?: boolean, children: React.ReactNode}> = ({transition, reenter = false, children}) => {
    if(transition.isEmpty) return <>{children}</>
    return (
        <ViewportListener onEnter={() => transition.activate()} onLeave={() => reenter && transition.activate(false)}>{children}</ViewportListener>
    )
}