/** @jsxImportSource @emotion/react */

import { SerializedStyles } from "@emotion/react";
import React from "react";
import {
  Column,
  Image,
  OnEnterTransition,
  Row,
  Title,
  useTransition,
  withDelay,
} from "../../components";
import { ReactComponent as LinkedInIcon } from "../../svgs/linkedin.svg";

const StyledExternalLink = styled.a(({ theme }) => ({
  color: theme.colors.secondary,
  "> svg": {
    fill: theme.colors.secondary,
    width: 24,
  },
  textDecoration: "none",
  margin: "2px 8px",
}));

import { desktop } from "../../theme";
import styled from "@emotion/styled";

type Member = {
  name: string;
  image: string;
  position: string;
  linkedIn?: string;
};
const members: Member[] = [
  {
    name: "Dennis Stopler",
    image: "denis.png",
    position: "Co-founder, CEO",
    linkedIn: "https://www.linkedin.com/in/denis-stolper-44aba91b6/",
  },
  {
    name: "Kellen Miller",
    image: "kellen.png",
    position: "Co-founder, CFO",
    linkedIn: "https://www.linkedin.com/in/kellen-b-miller/",
  },
  { name: "Chris Korkor", image: "chris.png", position: "CSO", linkedIn: "" },
  { name: "Kuuku Saah", image: "kuuku.png", position: "Advisor", linkedIn: "" },
  { name: "PY", image: "py.png", position: "COO", linkedIn: "" },
  {
    name: "GyZ",
    image: "gyz.png",
    position: "Partnership and Growth Lead",
    linkedIn: "",
  },
  { name: "Nico", image: "nico.png", position: "CTO", linkedIn: "" },
  { name: "Grinn", image: "grinn.png", position: "Dev", linkedIn: "" },
];

const LinkOrBox: React.FC<{
  href?: string | null;
  children: React.ReactNode;
}> = ({ href, children }) => {
  if (href) {
    return (
      <a href={href} css={{ textDecoration: "none" }}>
        {children}
      </a>
    );
  }
  return <>{children}</>;
};

const TeamMember: React.FC<{
  member: Member;
  css?: SerializedStyles;
  className?: string;
}> = ({ member, css, className }) => {
  return (
    <LinkOrBox href={member.linkedIn}>
      <Column
        css={[css, { margin: 16, alignItems: "center" }]}
        className={className}
      >
        <Image
          image={member.image}
          css={{
            height: 100,
            width: 100,
            [desktop]: { height: 150, width: 150 },
          }}
        />
        <h3
          css={(theme) => ({
            color: theme.colors.primary,
            margin: "auto",
            textAlign: "center",
          })}
        >
          {member.name}
        </h3>
        <h3
          css={(theme) => ({
            color: theme.colors.secondary,
            margin: "auto",
            textAlign: "center",
            fontSize: theme.fontSizes.small,
            display: "flex",
            alignItems: "center",
          })}
        >
          {member.position}
          {member.linkedIn && (
            <StyledExternalLink href={member.linkedIn}>
              <LinkedInIcon />
            </StyledExternalLink>
          )}
        </h3>
      </Column>
    </LinkOrBox>
  );
};

export const Team: React.FC = () => {
  const [css, cssTransition] = useTransition("opacity");
  return (
    <OnEnterTransition transition={cssTransition}>
      <Column>
        <Title i18nKey="team.title">
          <strong>Immortl </strong>Team
        </Title>
        <Row
          css={{
            flexWrap: "wrap",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          {members.map((m, i) => (
            <TeamMember
              key={m.name}
              member={m}
              css={withDelay(css, `${0.2 * (i + 1)}s`)}
            />
          ))}
        </Row>
      </Column>
    </OnEnterTransition>
  );
};
