import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { desktop, hideOnDesktop, hideOnMobile } from "../../theme";

type HM = {hideOnMobile?: boolean}
type HD = {hideOnDesktop?: boolean}

export const Row = styled.div<HM & HD>(({hideOnMobile: hm = false, hideOnDesktop: hd = false}) => css({
    display: "flex",
    flexDirection: "row",
    flex: 1,
    
}, hm && hideOnMobile, hd && hideOnDesktop))

type ColumnProps = {flex?: number, flexBasis?: string, center?: boolean} & HM & HD;

export const Column = styled.div<ColumnProps>(({flex, flexBasis, hideOnMobile: hm = false, hideOnDesktop: hd = false, theme}) => css({
    display: "flex",
    flexDirection: "column",
    flex,
    flexBasis,
    
}, hm && hideOnMobile, hd && hideOnDesktop))

export const FlowBox = styled.div<ColumnProps>(({flex, flexBasis, center = false, hideOnMobile: hm = false, hideOnDesktop: hd = false, theme}) => css({
    display: "flex",
    flexDirection: "column",
    flex,
    flexBasis,
    alignItems: center ? "center": "normal",
    [desktop]: {
        flexDirection: "row",
        justifyContent: center ? "center": "normal",
    }
}, hm && hideOnMobile, hd && hideOnDesktop))