/** @jsxImportSource @emotion/react */

import { Column, SubTitle, Text, Title } from "../components";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { desktop } from "../theme";

export const LegalPage = () => {
  const [content, setContent] = useState("");
  const theme = useTheme();
  useEffect(() => {
    fetch("/assets/files/legal.md")
      .then((res) => res.text())
      .then((text) => {
        console.log(text);
        setContent(text);
      });
  }, []);

  return (
    <Column
      css={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        padding: "0 5%",
        paddingTop: 200,
        background: theme.colors.bgBlack,
        [desktop]: { paddingLeft: "15%", paddingRight: "15%" },
      }}
    >
      <Title>
        <strong>Legal</strong> Notice
      </Title>
      <ReactMarkdown
        css={(theme) => ({
          wordBreak: "break-all",
          "> *": {
            color: theme.colors.white,
            fontWeight: 400,
          },
          a: {
            color: `${theme.colors.primary} !important`,
          },
        })}
      >
        {content}
      </ReactMarkdown>
    </Column>
  );
};
