/** @jsxImportSource @emotion/react */

import { useTranslation } from "react-i18next";
import {
  CallToActionButton,
  Column,
  FlowBox,
  Image,
  Row,
  SubTitle,
  Title,
  TranslatedList,
} from "../../components";
import { desktop } from "../../theme";

export const Token: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Column>
      <Title
        css={(theme) => ({ [desktop]: { textAlign: "start" } })}
        i18nKey="token.title"
      >
        <strong>Immortl </strong>Token
      </Title>
      <FlowBox>
        <Column flexBasis="50%">
          <SubTitle i18nKey="token.subtitle.1">
            Our own <strong>utility token</strong> which will be used for
            day-to-day transactions and payments.
          </SubTitle>
          <TranslatedList keyPrefix="token.highlights" n={3} />
          <CallToActionButton href="https://one-immortl.gitbook.io/documentation/immortl-v2-token">
            {t("learnMore")}
          </CallToActionButton>
        </Column>
        <Column
          flexBasis="50%"
          css={{
            justifyContent: "center",
            [desktop]: { paddingRight: 32, order: -1 },
          }}
        >
          <video
            autoPlay
            loop
            muted
            css={(theme) => ({
              zIndex: 10,
              width: "100%",
            })}
          >
            <source src="/assets/images/one-lightning.mp4" />
          </video>
        </Column>
      </FlowBox>
    </Column>
  );
};
