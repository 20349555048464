import { ReactComponent as TwitterIcon } from "../svgs/twitter.svg";
import { ReactComponent as InstagramIcon } from "../svgs/instagram.svg";
import { ReactComponent as YoutubeIcon } from "../svgs/youtube.svg";
import { ReactComponent as LinkedInIcon } from "../svgs/linkedin.svg";
import { ReactComponent as TelegramIcon } from "../svgs/telegram.svg";
import { ReactComponent as MediumIcon } from "../svgs/medium.svg";
import { ReactComponent as DiscordIcon } from "../svgs/discord.svg";
import { ReactComponent as GitbookIcon } from "../svgs/gitbook.svg";
import { Row } from "./structure";
import styled from "@emotion/styled";

const StyledExternalLink = styled.a(({ theme }) => ({
  color: theme.colors.primary,
  "> svg": {
    fill: theme.colors.primary,
    width: 24,
  },
  textDecoration: "none",
  margin: "2px 8px",
}));

const socials = [
  { icon: GitbookIcon, url: "https://one-immortl.gitbook.io/documentation/" },
  { icon: TwitterIcon, url: "https://twitter.com/one_immortl" },
  { icon: InstagramIcon, url: "https://www.instagram.com/one_immortl/" },
  {
    icon: YoutubeIcon,
    url: "https://www.youtube.com/channel/UCa2Ha_UIK0Kp-FyFAij8mPA",
  },
  { icon: LinkedInIcon, url: "https://www.linkedin.com/company/one-immortl" },
  { icon: TelegramIcon, url: "https://t.me/theoneimmortlchat" },
  { icon: MediumIcon, url: "https://one-immortl.medium.com/" },
  { icon: DiscordIcon, url: "https://discord.gg/qTpqVvEyXG" },
];

export const Socials = () => (
  <Row>
    {socials.map((s, i) => (
      <StyledExternalLink key={i} href={s.url} target="_blank">
        <s.icon width={24} height={24} />
      </StyledExternalLink>
    ))}
  </Row>
);
