import "@fontsource/montserrat";

export const theme = {
    colors: {
      primary: '#fbb200',
      accent: '#fbb200',
      secondary: "#BBBBBB",
      white: "white",
      bgBlack: "#050000"
    },
    fonts: {
        primary: "Monserrat, Sans-serif"
    },
    fontSizes: {
        small: 14,
        medium: 22,
        big: 32,
        giant: 50
    }
  }

export type OneNationTheme = typeof theme
  