/** @jsxImportSource @emotion/react */

import { useContext } from "react";
import { BrowserRouter, Location, Navigate, Route, Routes } from "react-router-dom";
import { Column } from "../components";
import { AnchorContext, LocationListener } from "../components/navigation";
import { HomePage, LegalPage, PrivacyPage } from "../pages";
import { Footer } from "./Footer";
import { NavBar } from "./NavBar";

export const Router = () => {
    const anchorCtx = useContext(AnchorContext);
    const onLocationChange = (newLoc: Location, oldLoc: Location | null) => {
        const anchorInLocation = newLoc.hash.substring(1)
        if(anchorInLocation) {
            setTimeout(() => {
                anchorCtx.scrollTo(anchorInLocation)
            }, 100)
            
        }
    }

    return <Column css={{minHeight: "100vh"}}>
        <BrowserRouter>
            <LocationListener onLocationChangeChange={onLocationChange} />
            <NavBar />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/legal" element={<LegalPage />} />
                <Route path="/privacy" element={<PrivacyPage />} />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    </Column>
}