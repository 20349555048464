/** @jsxImportSource @emotion/react */

import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { Column, FlowBox, Socials, StyledA, SubTitle, Text, useTransition } from "../components";
import { desktop } from "../theme";


const SFooter = styled.nav(({theme}) => ({
    display: "flex",
    flexDirection: "row",
    backgroundColor:theme.colors.bgBlack,
    padding: "32px 5% 65px 5%",
    borderTop: `1px solid ${theme.colors.primary}`,

    [desktop]: {
        padding: "0 5%",
    }
    
}))

export const Footer = () => {
    const [poweredCss, poweredTransition] = useTransition("opacity")
    return (
        <SFooter>
            <FlowBox css={{
                justifyContent: "space-between !important",
                alignItems: "center",
                flex: 1
            }}>
                <Link to="/">
                    <FlowBox css={{alignItems: "center"}}>
                    <img src="/assets/images/one-badge.png" 
                        css={{height: 100}}
                        alt="app logo" />
                    </FlowBox>
                </Link>
                <Column css={{"> *": {marginTop: 16}}}>
                    <Text css={(theme) => ({color: theme.colors.primary, textAlign: "center", fontSize: theme.fontSizes.medium})}>One Immortl Technology LLC Dubai, United Arab Emirates</Text>
                </Column>
                <Column css={{
                    padding: 16,
                    alignItems: "center",
                    [desktop]: {
                        alignItems: "normal"
                    },
                    "> *": {
                        textDecoration: "none",
                        color: "white",
                        fontWeight: 600,
                        marginTop: 8
                    }
                }}>
                    <a href="mailto:info@one-immortl.com">CONTACT</a>
                    <Link to="/legal">LEGAL NOTICE</Link>
                    <Link to="/privacy">PRIVACY PROTECTION</Link>
                    <Socials />
                </Column>
            </FlowBox>
        </SFooter>
    );
}