/** @jsxImportSource @emotion/react */

import { useTranslation } from "react-i18next";
import { rangeTo } from "../utils";

export const TranslatedList: React.FC<{
  keyPrefix: string;
  n: number;
  checkmark?: boolean;
}> = ({ keyPrefix, n, checkmark = true }) => {
  const { t } = useTranslation("translation", { keyPrefix });
  return (
    <ul>
      {rangeTo(n).map((i) => (
        <li key={i}>{t(i + 1 + "")}</li>
      ))}
    </ul>
  );
};
