import React, { useContext, useEffect, useRef } from "react";


const anchors: {[k: string]: () => any} = {}

export const AnchorContext = React.createContext({
    register: (key: string, scrollTo: () => any): void => {
        anchors[key] = scrollTo
    },
    degister: (key: string) => {
        delete anchors[key]
    },
    scrollTo: (anchor: string): void => {
        anchors[anchor]?.();
    },
    findMatch: (anchor: string): boolean => {
        return !!anchors[anchor];
    }
})

export const Anchored: React.FC<JSX.IntrinsicAttributes & {id: string, as?: React.ElementType,  children?: React.ReactNode}> = ({id, children, as: Tag = "div", ...props}) => {
    const ref = useRef<HTMLDivElement>(null);
    const anchorCtx = useContext(AnchorContext)
    useEffect(() => {
        anchorCtx.register(id, () => ref.current?.scrollIntoView({behavior: "smooth"}))
        return () => anchorCtx.degister(id)
    }, [id, anchorCtx])
    return <Tag ref={ref} {...props} id={id}>{children}</Tag>
}

export const Anchor: React.FC<{id: string}> = ({id}) => <Anchored id={id}></Anchored>