/** @jsxImportSource @emotion/react */

import React, { ImgHTMLAttributes } from "react";
import { OnEnterTransition, Transitions, useTransition } from "./css-transitions";

export const Image: React.FC<Omit<ImgHTMLAttributes<any>, "src"> & {image: string, transition?: Transitions}> = ({image, alt, transition, ...props}) => {
    
    const [css, cssTransition] = useTransition(transition || "none")
    
    return (
        <OnEnterTransition transition={cssTransition}>
            <img 
            css={[css, {borderRadius: 4, width: "100%"}]}
            src={`/assets/images/${image}`}
            alt={alt}
            {...props}
            
        />
        </OnEnterTransition>
    )
}