/** @jsxImportSource @emotion/react */

import {
  Column,
  Image,
  OnEnterTransition,
  Row,
  SubTitle,
  Title,
  useTransition,
  withDelay,
} from "../../components";
import { desktop } from "../../theme";

type Partner = { name: string; image: string; uri: string };

const partners: Partner[] = [
  { name: "polygon", image: "polygon.png", uri: "https://polygon.technology" },
  {
    name: "polygon alliance",
    image: "polygon-alliance.png",
    uri: "https://polygonalliance.com",
  },
  {
    name: "One Nation",
    image: "one-nation-logo.png",
    uri: "https://one-nation.xyz/",
  },
  {
    name: "muniq real estate",
    image: "muniqrealestate.png",
    uri: "https://muniqre.com/",
  },
  {
    name: "Moon Vault",
    image: "moonvault-logo.png",
    uri: "https://app.moon-vault.com/",
  },
  {
    name: "safemoon",
    image: "safemoon-logo.png",
    uri: "https://safemoon.com/",
  },
  {
    name: "pinksale",
    image: "pinksale-logo.png",
    uri: "https://www.pinksale.finance/?chain=BSC",
  },
  {
    name: "polygon-guild-munich",
    image: "PGM.png",
    uri: "https://polygon-guild-munich.com",
  },
  {
    name: "Dexview",
    image: "dexview.png",
    uri: "https://www.dexview.com/",
  },
];

const PartnerBox: React.FC<{ partner: Partner; className?: string }> = ({
  partner,
  className,
}) => {
  return (
    <Column css={{ margin: 16, alignItems: "center" }} className={className}>
      <a
        href={partner.uri}
        css={{
          transition: "transform .2s",
          "&:hover": { transform: "scale(1.2)" },
        }}
        target="_blank"
        rel="noreferrer"
      >
        <Image
          image={partner.image}
          alt={partner.name}
          css={{
            height: 75,
            width: "auto",
            [desktop]: { height: 100, width: "auto" },
          }}
        />
      </a>
    </Column>
  );
};
export const Partners: React.FC = () => {
  const [css, cssTransition] = useTransition("opacity");
  return (
    <OnEnterTransition transition={cssTransition}>
      <Column>
        <Title i18nKey="partners.title">
          <strong>Immortl </strong>Partners
        </Title>
        <Row css={{ flexWrap: "wrap", justifyContent: "space-around" }}>
          {partners.map((p, i) => (
            <PartnerBox
              key={p.name}
              partner={p}
              css={withDelay(css, `${0.2 * (i + 1)}s`)}
            />
          ))}
        </Row>
      </Column>
    </OnEnterTransition>
  );
};
