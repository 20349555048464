/** @jsxImportSource @emotion/react */

import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { Column, FlowBox, Row, Socials, Spacer, StyledA } from "../components";
import { desktop, hideOnDesktop } from "../theme";

import { ReactComponent as HomeIcon } from "../svgs/home.svg";
import { ReactComponent as MoreIcon } from "../svgs/more.svg";
import { ReactComponent as PaymentIcon } from "../svgs/payments.svg";
import { ReactComponent as SwapIcon } from "../svgs/swap.svg";

import { useCallback, useEffect, useState } from "react";

const Nav = styled.nav(({ theme }) => [
  {
    position: "absolute",
    top: 0,
    maxWidth: "100vw",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
]);

const MobileBottomNav = styled.nav(({ theme }) => [
  {
    position: "fixed",
    display: "flex",
    bottom: -1,
    height: 65,
    width: "100vw",
    background: theme.colors.bgBlack,
    borderTop: `1px solid ${theme.colors.primary}`,
    zIndex: 101,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  hideOnDesktop,
]);

const StyledLink = styled(Link)(({ theme }) => ({
  fontFamily: theme.fonts.primary,
  color: theme.colors.primary,
  textDecoration: "none",
  fontWeight: 900,
  padding: 16,
  textShadow: `0px 0px 10px ${theme.colors.primary}`,
}));

const StyledMobileLink = styled(Link)(({ theme }) => ({
  fontFamily: theme.fonts.primary,
  color: theme.colors.primary,
  textDecoration: "none",
  fontSize: theme.fontSizes.small,
  fontWeight: 900,
  textShadow: `0px 0px 10px ${theme.colors.primary}`,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const StyledMobileButton = styled.button(({ theme }) => ({
  fontFamily: theme.fonts.primary,
  color: theme.colors.primary,
  textDecoration: "none",
  fontSize: theme.fontSizes.small,
  fontWeight: 900,
  textShadow: `0px 0px 10px ${theme.colors.primary}`,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  background: "transparent",
  border: 0,
}));
const DesktopNav = () => (
  <Nav>
    <Row
      css={{
        padding: "8px 16px",
        justifyContent: "center",
        zIndex: 10,
        [desktop]: { justifyContent: "flex-start" },
      }}
    >
      <Link css={{ display: "flex" }} to="/">
        <FlowBox css={{ alignItems: "center" }}>
          <img
            src="/assets/images/one-immortl.png"
            css={{ height: 35 }}
            alt="app logo"
          />
        </FlowBox>
      </Link>
      <Row hideOnMobile css={{ alignItems: "center" }}>
        <Spacer />
        <Column>
          <Row>
            <Spacer />
            <Socials />
          </Row>
          <Row css={{ alignItems: "center" }}>
            <StyledLink to="/">Home</StyledLink>
            <StyledLink to="/#swap">Swap</StyledLink>
            <StyledLink to="/#payments">Payments</StyledLink>
            <StyledLink to="/#token">Token</StyledLink>
            <StyledLink to="/#NFTs">NFTs</StyledLink>
            <StyledLink to="/#team">Team</StyledLink>
            <StyledLink to="/#partners">Partners</StyledLink>
            <StyledA outlined href="https://app.one-immortl.io/">
              Enter DApp
            </StyledA>
          </Row>
        </Column>
      </Row>
    </Row>
  </Nav>
);

const DesktopNavSticky: React.FC<{ visible: boolean }> = ({ visible }) => (
  <Nav
    css={(theme) => [
      {
        position: "fixed",
        height: 0,
        visibility: "hidden",
        background: theme.colors.bgBlack,
        zIndex: 20,
        borderBottom: `1px solid ${theme.colors.primary}`,
        transition: "all .1s ease",
      },
      visible && { [desktop]: { height: 60, visibility: "visible" } },
    ]}
  >
    <Row
      css={{
        padding: "8px 16px",
        justifyContent: "center",
        [desktop]: { justifyContent: "flex-start" },
      }}
    >
      <Link css={{ display: "flex", alignItems: "center" }} to="/">
        <img
          src="/assets/images/one-badge.png"
          css={{ height: 45 }}
          alt="app logo"
        />
      </Link>
      <Row hideOnMobile css={{ alignItems: "center" }}>
        <Spacer />
        <StyledLink
          to="/"
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          Home
        </StyledLink>
        <StyledLink to="/#swap">Swap</StyledLink>
        <StyledLink to="/#payments">Payments</StyledLink>
        <StyledA outlined href="https://app.one-immortl.io/">
          Enter DApp
        </StyledA>
      </Row>
    </Row>
  </Nav>
);

export const NavBar = () => {
  const theme = useTheme();
  const [sticky, setSticky] = useState(window.pageYOffset > 100);
  const [mobileMoreVisible, setMobileMoreVisible] = useState(false);

  const handleScroll = useCallback(() => {
    const position = window.pageYOffset;
    setSticky(position > 100);
  }, []);

  const handleClick = useCallback(() => {
    if (mobileMoreVisible) {
      setMobileMoreVisible(false);
    }
  }, [mobileMoreVisible]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    document.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("click", handleClick);
    };
  }, [handleScroll, handleClick]);

  return (
    <header>
      <DesktopNav />
      <DesktopNavSticky visible={sticky} />
      <MobileBottomNav>
        <Row
          css={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <StyledMobileLink
            to="/"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            <HomeIcon fill={theme.colors.primary} height={24} />
            Home
          </StyledMobileLink>
          <StyledMobileLink to="/#swap">
            <SwapIcon fill={theme.colors.primary} height={24} width={24} />
            Swap
          </StyledMobileLink>
          <StyledMobileLink to="/#payment">
            <PaymentIcon fill={theme.colors.primary} height={24} />
            Payment
          </StyledMobileLink>
          <StyledA
            outlined
            href="https://app.one-immortl.io/"
            css={{ marginLeft: 0 }}
          >
            DApp
          </StyledA>
        </Row>
      </MobileBottomNav>
    </header>
  );
};
