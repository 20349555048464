/** @jsxImportSource @emotion/react */

import styled from "@emotion/styled";
import { Column, FlowBox, Image, SubTitle, Title } from "../../components";
import { desktop } from "../../theme";

const Text = styled(SubTitle)(({ theme }) => ({
    padding: 2,
    paddingTop: 8,
    textAlign: "center",
    minHeight: 50,
    textShadow: "0px 0px 5px #000000",
    [desktop]: {
        fontSize: theme.fontSizes.medium,
        padding: 4,
        minHeight: 150,
    }
}))

export const Loyalty = () => (
    <Column css={theme => ({
        minHeight: "70vh",
        padding: "32px 10%",
        background: theme.colors.bgBlack,
        [desktop]: {
            minHeight: "50vh",
        }
    })}>
        <Title><strong>Immortl </strong> Loyalty</Title>
        <FlowBox>
            <Column flexBasis="65%">
                <SubTitle>
                    Make a name for yourself within the Immortl community - Complete Quests, Earn Rewards, and diversify your portfolio using the Immortl app. 
                </SubTitle>

                <SubTitle css={{marginTop: 32, marginBottom: 16}}>
                <strong>Rewards fit for gods:</strong>
                </SubTitle>
                <SubTitle>
                Holders of Anubis NFTs will automatically qualify for the Immortl Genesis Program, receiving a handful of benefits, and access to major project utilities.The highest tier status available, Genesis Program participants automatically receive higher rewards, and discounts with Immortl partners when using the Immortl Wallet.To flaunt their status, Genesis holders will be airdropped a digital card, in addition to a very real physical credit card, adorned with their minted character on the front., this means <br/><br />
                -High Rewards in staking and farming<br/>
                -Reduced transaction fees with the Immortl Wallet<br/>
                -Wallet avatars<br/>
                </SubTitle>
                <SubTitle  css={{marginTop: 32, marginBottom: 16}}>
                    <strong>D.NFTS: Dnfts Graphic</strong>
                </SubTitle>
                <SubTitle>
                    The unique debut NFT series will yield real-world dividends from profits gained from brick-and-mortar cafesThe first series will be directly linked to the flagship One Nation Café in Munich, Germany.Currently entering beta-testing, these NFTs will provide a number of benefits in the ONE ecosystem, as well as regular return on investment.
                    More details on functionality and efficiency will be released as beta-testing progresses.The first round of NFTs will be distributed through giveaways and sold at a discount in the interest of testing out the dividend payout process. Additional NFT series will be released as more cafes open across the map.The first One-Nation café has been open in Munich since December 2021
                </SubTitle>

            </Column>
            <Column flexBasis="35%">
                <Image image="anubis.jpeg" css={{position: "sticky", top: 100}} />
            </Column>
        </FlowBox>
    </Column>
)