export const en = {
  learnMore: "Learn More",
  tryNow: "Try now",
  whitepaper: "Whitepaper",
  title: {
    line1: "One Immortl",
    goOne: "GO ONE",
    subtitle:
      "Multi-brand loyalty program with an integrated online and blockchain payment system",
  },
  swap: {
    title: "<0>Immortl </0>Swap",
    subtitle: "Find the best <1>swap rate</1> on all supported chains",
    highlights: {
      1: "Supports BSC, Polygon, Fantom, Cronos",
      2: "Aggregates over multiple dexes",
      3: "Save time and money",
    },
    swapNow: "Swap Now",
  },
  payments: {
    title: "<0>Immortl </0>Enterprise Solution",
    privateUsage: {
      title: "<0>Users</0>",
      subtitle: "Enjoy seamless transactions with real world businesses.",
      highlights: {
        1: "Trustless On-chain Payments",
        2: "Pay or get paid in crypto",
        3: "Supports 20+ cryptocurrencies",
      },
    },
    enterpriseSolution: {
      title: "<0>Merchants</0>",
      subtitle: {
        1: "Seamless crypto payment system for your business.",
        2: "Grow your userbase by accepting crypto payments.",
      },
      highlights: {
        1: "Access Management",
        2: "Invoice raising system",
        3: "Integrated Swap",
      },
      introLink: "Want to integrate crypto payments into your business?",
      learnMore: "Learn More",
    },
  },
  wallet: {
    title: "<0>Immortl </0>Wallet",
    subtitle: "Non-custodial <1>crypto wallet</1>",
    description: "Easily access it anywhere and anytime",
    highlights: {
      1: "Portfolio Overview",
      2: "Integrated fiat on/off ramp",
      3: "Dex aggregator",
      4: "P2P crypto transfers",
      5: "Invoice raising system",
      6: "Escrow services",
    },
  },
  token: {
    title: "<0>Immortl </0>Token",
    subtitle: {
      1: "The <1>Immortl</1> V2 token is bringing <1>Immortl</1>’s value of sustainability to the <1>Polygon Network</1>.",
    },
    highlights: {
      1: "Carbon neutral transactions",
      2: "Staking tiering system",
      3: "Rewards on payments",
    },
  },
  team: {
    title: "<0>Immortl </0>Team",
  },
  partners: {
    title: "<0>Immortl </0>Partners",
  },
  nfts: {
    title: "<0>Immortl </0>Genesis NFTs",
    link: "Get your NFT",
    subtitle: "Anubis NFTs, Holders will be given:",
    highlights: {
      1: "Greater staking rewards",
      2: "Reduced transaction fees",
      3: "Immortl Wallet avatars",
    },
  },
};

export type Translation = typeof en;
